@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* 스크롤바 설정*/
  *::-webkit-scrollbar:not(body) {
    /* width: 6px; */
  }

  /* 스크롤바 막대 설정*/
  *::-webkit-scrollbar-thumb:not(body) {
    /* background: #e0e0e0; */
    /* border-radius: 3px; */
  }

  /* 스크롤바 뒷 배경 설정*/
  *::-webkit-scrollbar-track:not(body) {
    /* background-color: transparent; */
  }

  /* total width */
  *::-webkit-scrollbar {
    /* background-color: rgba(0, 0, 0, 0); */
    /* width: 16px; */
    /* height: 16px; */
    /* z-index: 999999; */
  }

  /* background of the scrollbar except button or resizer */
  *::-webkit-scrollbar-track {
    /* background-color: rgba(0, 0, 0, 0); */
  }

  /* scrollbar itself */
  *::-webkit-scrollbar-thumb {
    /* background-color: rgba(0, 0, 0, 0); */
    /* border-radius: 16px; */
    /* border: 0px solid #fff; */
  }

  /* set button(top and bottom of the scrollbar) */
  *::-webkit-scrollbar-button {
    /* display: none; */
  }

  /* scrollbar when element is hovered */
  body:hover::-webkit-scrollbar-thumb {
    /* background-color: #a0a0a5; */
    /* border: 4px solid #fff; */
  }

  /* scrollbar when scrollbar is hovered */
  body::-webkit-scrollbar-thumb:hover {
    /* background-color: #a0a0a5; */
    /* border: 4px solid #fff; */
  }

  html,
  body,
  div,
  span,
  applet,
  object,
  iframe,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  blockquote,
  pre,
  a,
  button,
  abbr,
  acronym,
  address,
  big,
  cite,
  code,
  del,
  dfn,
  em,
  img,
  ins,
  kbd,
  q,
  s,
  samp,
  small,
  strike,
  strong,
  sub,
  sup,
  tt,
  var,
  b,
  u,
  i,
  center,
  dl,
  dt,
  dd,
  ol,
  ul,
  li,
  fieldset,
  form,
  label,
  legend,
  table,
  caption,
  tbody,
  tfoot,
  thead,
  tr,
  th,
  td,
  article,
  aside,
  canvas,
  details,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  menu,
  nav,
  section,
  summary,
  time,
  mark,
  audio,
  video {
    margin: 0;
    padding: 0;
    border: 0 solid #fff;
    font-size: 100%;
    vertical-align: baseline;
    word-break: keep-all;
    -ms-word-break: break-all;
  }

  html,
  body,
  div,
  span,
  button,
  select,
  input,
  textarea {
    font-family: 'Pretendard Variable', 'Pretendard', 'Noto Sans Arabic', 'M PLUS 1p', 'Apple Color Emoji', 'Noto Color Emoji' !important;
    outline: none;
  }

  body {
    min-width: 1280px;
    color: #121212;
    letter-spacing: -0.5px;
    background: #f5f5f5;
  }

  .a11y {
    position: absolute;
    overflow: hidden;
    height: 1px;
    width: 1px;
    border: 0 solid #fff;
    clip: rect(1px, 1px, 1px, 1px);
    clip-path: inset(50%);
    word-break: initial;
    word-wrap: initial;
  }

  input[type='checkbox'],
  input[type='radio'] {
    display: none;
  }

  input[type='radio'] + label {
    cursor: pointer;
  }

  textarea {
    resize: none;
  }
}

@layer components {
  .text-h1 {
    font-size: 44px;
    font-weight: bold;
    line-height: 1.09;
    letter-spacing: -0.88px;
  }

  .text-h4 {
    font-size: 26px;
    font-weight: 600;
    line-height: 1.23;
    letter-spacing: -0.52px;
  }
}

@layer utilities {
  .gray-scrollbar {
    &::-webkit-scrollbar-thumb {
      background-color: #e0e0e0;
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background-color: #eeeeee;
      border-radius: 2px;
    }
  }

  .gray-scroll-6 {
    &::-webkit-scrollbar {
      width: 6px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #eee; /* 스크롤바 색상 */
      border-radius: 3px;
    }

    &::-webkit-scrollbar-track {
      background-color: transparent; /* 트랙의 배경을 투명하게 설정 */
        
    }
  }

  /** 모달창 배경색 있을 경우 스크롤바 컬러 다르니 참고해주세요 라고 하시네요.. ^^ */
  .modal-gray-scroll {
    &::-webkit-scrollbar {
      width: 6px;
      height: 6px;
    }

    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 3px;
    }

    &::-webkit-scrollbar-thumb {
      background: #bdbdbd;
      border-radius: 3px;
      height: 20px; /* 최소 높이 설정 */

    }
  }

  .x-scroll-none::-webkit-scrollbar {
    display: none;
  }

  .modal-content-scrollbar {
    &::-webkit-scrollbar {
      background-color: transparent;
      width: 26px;
    }

    &::-webkit-scrollbar-thumb {
      background: #e0e0e0;
      background-clip: padding-box;
      border-color: transparent;
      border-radius: 999px;
      border-style: solid;
      border-width: 6px 10px;
      width: 6px;
    }
  }

  .user-description {
    &::-webkit-scrollbar {
      width: 18px;
      background: transparent;
    }
    &::-webkit-scrollbar-thumb {
      width: 6px;
      background: #e0e0e0;
      background-clip: padding-box;
      border-radius: 9999px;
      border-top: 4px solid transparent;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 4px solid transparent;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
    }
    &::-webkit-input-placeholder {
      font-weight: 400;
      color: #bdbdbd;
    }
  }

  .emoji-popup-wrapper {
    aside {
      height: 272px !important;
      width: 260px !important;
    }

    .epr-header {
      height: 40px !important;
      display: flex;
      align-items: center;
      padding: 0 16px;
      justify-content: space-between;

      & .epr-category-nav {
        width: 100% !important;
        padding: 0 !important;
      }
    }

    .epr-body {
      height: 218px !important;
      flex: none !important;
      padding: 0 0px 12px 16px;

      &::-webkit-scrollbar {
        width: 12px;
        background: transparent;

        &-thumb {
          width: 4px;
          background: #e0e0e0;
          background-clip: padding-box;
          border-radius: 999px;
          border: 4px solid transparent;
          border-bottom: 2px solid transparent;
          border-top: 2px solid transparent;
        }

        &-track {
          background: transparent;
        }
      }

      & .epr-emoji-list .epr-emoji-category-label {
        display: none !important;
      }

      & .epr-emoji-list .epr-emoji-category {
        margin-top: 6px;

        & .epr-btn {
          margin-top: 6px;
        }
      }
    }

    .EmojiPickerReact {
      border: none !important;
      --epr-category-icon-active-color: #fff !important;
      --epr-category-navigation-button-size: 24px !important;
      --epr-emoji-size: 24px !important;
      --epr-emoji-padding: 3px !important;
      --epr-category-padding: 0 !important;

      & button.epr-emoji {
        width: 24px !important;
        height: 24px !important;
      }
    }
  }
}

@layer utilities {
  .scroll-none::-webkit-scrollbar {
    display: none;
  }
}
